import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  useRoute
} from "vue-router";

import { useSidebarStore, catchPath } from "../store/sidebar"; //系统菜单权限
import Home from "@/views/home.vue";
// import  router from "@/router";
const route: any = useRoute();
import { useTagsStore } from "../store/tags";
const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/dashboard"
  },
  {
    path: "/dashboard",

    component: Home,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "Home",
          permiss: "1"
        },
        component: () => import("../views/home/index.vue")
      }
    ]
  },

  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录"
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue")
  }
];

const router: any = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to: any, from: any, next: any) => {
  document.title = `Official mall backend management system`;
  const role = localStorage.getItem("ms_loginName");
  // const permiss = usePermissStore();
  if (!role && to.path !== "/login") {
    next("/login");
  } else if (role && to.path == "/login") {
    next("/dashboard");
  } else {
    const permiss = useSidebarStore();
    let routerData: any = localStorage.router
      ? JSON.parse(localStorage.router)
      : "";
    if (routerData) {
      permiss
        .generateRouters(routerData)
        .then((res: any) => {
          res.forEach((value: any, index: number) => {
            router.addRoute(value);
          });
        })
        .then(() => {
          setTimeout(() => {
            let path = location.hash
              ? location.hash.split("#")
              : ["", location.pathname];
            if (path && path.length > 1) {
              let tags = useTagsStore();
              let detail = routes.filter(list => {
                return list.path == "/detail";
              });
              let fullPath = path[1];

              let pathQuery = path[1].split("?");
              let paths = "";
              paths = pathQuery[0];

              let children: any = detail[0]?.children;
              let getSameObj = children?.filter((list: any) => {
                return list.path == paths;
              });

              const findItemNested = (arr: any, itemId: any, nestingKey: any) =>
                arr.reduce((a: any, item: any) => {
                  if (a) return a;
                  if (item.routePath === itemId) return item;
                  if (item[nestingKey])
                    return findItemNested(item[nestingKey], itemId, nestingKey);
                }, null);
              let routers = JSON.parse(localStorage.routers);
              //   console.log(JSON.stringify(routers)+'->'+paths)
              const res = findItemNested(routers, paths, "children");

              let currentRoute = router.currentRoute.value;
              let _CURRENTPATH_STR: any =
                sessionStorage.getItem("_CURRENTPATH_");
              let _path, _name, _title;
              if (_CURRENTPATH_STR) {
                let _CURRENTPATH_JSON = JSON.parse(_CURRENTPATH_STR);
                _path = _CURRENTPATH_JSON.path;
                _name = _CURRENTPATH_JSON.name;
                _title = _CURRENTPATH_JSON.title;
              }

              // console.log(router,'currentRoute')

              sessionStorage.setItem(
                "_CURRENTPATH_",
                JSON.stringify({
                  path: currentRoute.fullPath || _path,
                  name: currentRoute.name || _name,
                  title:
                    currentRoute.query.title ||
                    currentRoute.meta.title ||
                    _title
                })
              );

              if (getSameObj && getSameObj.length) {
                tags.setTagsItem({
                  name: getSameObj[0].name,
                  title: currentRoute.query.title || getSameObj[0].meta.title,
                  path: fullPath
                });
              } else {
                if (paths != "/dashboard") {
                  let name;
                  if (res) {
                    name = res.routePath
                      ? res.routePath
                          .split("/")
                          .map((ele: any) => {
                            return firstToUpper(ele);
                          })
                          .join("") + "Name"
                      : "";
                  }

                  tags.setTagsItem({
                    name: name || (res ? res.fullPath : ""),
                    title: res ? res.name : "",
                    path: fullPath
                  });
                }
              }
            }
          }, 500);
        });
    }
    if (to.matched.length) {
      if (to.matched.length === 1 && to.matched[0].path == "/dashboard") {
        sessionStorage.setItem(
          "_CURRENTPATH_",
          JSON.stringify({
            path: "/dashboard",
            name: "dashboardName",
            title: "Home"
          })
        );

        next();
      } else {
        next();
      }
    } else {
      if (catchPath.includes(to.path)) {
        next({ ...to, replace: true });
      } else {
        next("/dashboard");
      }
    }
  }
});
function firstToUpper(str: string) {
  return str.toLowerCase().replace(/( |^)[a-z]/g, L => L.toLowerCase());
}

export default router;
