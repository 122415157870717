<template>
	<v-header />
	<v-sidebar />
	<div class="content-box" :class="{ 'content-collapse': sidebar.collapse }">
		<v-tags></v-tags>
		
		<div class="content">
			
			<router-view  #default="{Component}">
				<!-- <transition name="move" mode="out-in">  -->
					<!-- :include="tags.nameList" -->
					<keep-alive :max="10"  >
						<component v-if="$route.meta.keepAlive" :key="$route.fullPath"  :is="Component"></component>
					</keep-alive>
					<component v-if="!$route.meta.keepAlive"   :is="Component"></component>
				<!-- </transition> -->
			</router-view>
		</div>
	</div>
</template>
<script setup lang="ts">
import { useSidebarStore } from '../store/sidebar';
import { useTagsStore } from '../store/tags';
import vHeader from '../components/header.vue';
import vSidebar from '../components/sidebar.vue';
import vTags from '../components/tags.vue';
import { useRoute } from 'vue-router';

let route:any = useRoute();
//.log(route.matched,'route')



const sidebar = useSidebarStore();
const tags = useTagsStore();
</script>

<style scoped>

 ::v-deep(.is-vertical){
	display: block!important;
	width: 8px !important;
}

::v-deep(.el-scrollbar__bar.is-horizontal){
	display: block!important;
	height: 8px !important;
}

::v-deep(.el-scrollbar){
	--el-scrollbar-opacity: 1!important;
	--el-scrollbar-hover-opacity:1!important;
	/* padding-bottom: 20px!important; */
}
::v-deep(.el-scrollbar__thumb){
	cursor: pointer;
    background-color: #687c97;
}
::v-deep(.el-scrollbar__thumb:hover){
	cursor: pointer;
    background-color: #236ce9;
}
::v-deep(.el-table__body-wrapper){
	padding-bottom: 20px!important;
}
::v-deep(.el-scrollbar){
	padding-bottom: 18px;
}



</style>
