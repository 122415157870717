<template>
  <div class="header">
    <!-- 折叠按钮 -->
    <div class="collapse-btn" @click="collapseChage">
      <el-icon v-if="sidebar.collapse"><Expand /></el-icon>
      <el-icon v-else><Fold /></el-icon>
    </div>
    <div class="logo">Official mall backend management system</div>
    <div class="header-right">
      <div class="header-user-con">
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <!-- {{ username ?  username.substring(0,2):''}} -->
            user
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <div class="username">{{ username }}</div>
              <el-dropdown-item divided command="loginout"
                >sign out</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, reactive, nextTick } from "vue";
import { useSidebarStore } from "../store/sidebar";
import { useRouter } from "vue-router";
// import imgurl from '../assets/img/img.jpg';
import { ElMessage } from "element-plus";

let roleName = localStorage.getItem("userInfo");
let username: string | null;
if (roleName) {
  let sysUser = JSON.parse(roleName).sysUser;
  username = sysUser.userName;
}

let menuList: any = reactive({
  data: [],
});

// nextTick(()=>{
// 	productInteractor.getCurrentCustomerNamesApi().then((res:any)=>{
// 		if(res.data.length>2){
// 			menuList.data = res.data.filter((rs:any,i:number)=>i<2)
// 			menuList.data[2] = '...'
// 		}else{
// 			menuList.data = res.data;
// 		}
// 		// //.log(menuList,11111)
// 	})
// })

const sidebar = useSidebarStore();
// 侧边栏折叠
const collapseChage: any = () => {
  sidebar.handleCollapse();
};

onMounted(() => {
  if (document.body.clientWidth < 1500) {
    collapseChage();
  }
});

function clear() {
  let cache = [
    "logInfo",
    "userInfo", 
    "ms_loginName",
    "routers",
    "router",
    "token"
  ];
  cache.forEach((list) => {
    localStorage.removeItem(list);
  });
} 
// 用户名下拉菜单选择事件
const router = useRouter();
const handleCommand = (command: string) => {
  if (command == "loginout") {
    // localStorage.removeItem('ms_loginName');
    sessionStorage.removeItem("_CURRENTPATH_");
    ElMessage.success("Success LoginOut");
    setTimeout(() => {
      router.push("/login");
      clear();
      location.reload();
    }, 300);
  } else if (command == "user") {
    router.push("/user");
  }
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
}
.collapse-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  float: left;
  padding: 0 21px;
  cursor: pointer;
}
.header .logo {
  float: left;
  /* width: 250px; */
  line-height: 70px;
  /* background: red; */
}
.header-right {
  float: right;
  padding-right: 50px;
}
.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.btn-bell-badge {
  position: absolute;
  right: 4px;
  top: 0px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-lx-notice {
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin-left: 20px;
}
.el-dropdown-link {
  /* color: #fff;
	cursor: pointer;
	display: flex;
	align-items: center; */
  color: #282c34;
  cursor: pointer;
  align-items: center;
  width: 34px;
  background: #edf2f6;
  height: 34px;
  line-height: 34px;
  overflow: hidden;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
}
.el-dropdown-menu__item {
  text-align: center;
}
.username {
  padding: 0 10px;
  text-align: center;
  color: #606266;
}
</style>
