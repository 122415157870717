<template>
  <div class="tags" v-if="tags.show">
    <ul>
      <li
        class="tags-li"
        v-for="(item, index) in tags.list"
        :class="{ active: isActive(item.path) }"
        :key="index"
      >
        <router-link :to="item.path" class="tags-li-title">{{
          item.title
        }}</router-link>
        <el-icon v-if="index" @click="closeTags(index)"><Close /></el-icon>
      </li>
    </ul>
    <!-- <el-tabs
			v-model="editableTabsValue"	
			type="card"

			closable
			@tab-remove="closeTags"
			@tab-click="handleClick"
		>
			<el-tab-pane
			v-for="(item, index) in tags.list"
			:key="item.name"
			:label="item.title"
			:name="item.path"
			>
			{{ item.title }}
			</el-tab-pane>
		</el-tabs> -->

    <div class="tags-close-box">
      <el-dropdown @command="handleTags">
        <el-button size="small" type="primary">
          Label options
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu size="small">
            <el-dropdown-item command="other">Close other</el-dropdown-item>
            <el-dropdown-item command="all">Close all</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTagsStore } from "../store/tags";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { ref, watch } from "vue";

const route = useRoute();
const router = useRouter();
const isActive = (path: string) => {
  return path === route.fullPath;
};

let tags = useTagsStore();

let editableTabsValue = ref(null);

// if(tags.list && tags.list.length>1){
// 	tagNameLst = tags.list;
// }else{
// 	if(localStorage.tagList && JSON.parse(localStorage.tagList).length){
// 		tagNameLst = JSON.parse(localStorage.tagList)
// 	}else{
// 		tagNameLst = [];
// 	}

// }
// //.log(tagNameLst,'tagNameLst')

//点击标签
function handleClick(val: any) {
  // //.log(val.props.name,1111)
  router.push(val.props.name);
}

// 关闭单个标签
const closeTags = (index: number) => {
  // const closeTags = () => {

  //使用tab切换
  // //.log(editableTabsValue.value,999)
  // let index:number = 0;
  // tags.list.filter((item,i)=>{
  // 	if(item.name == editableTabsValue.value){
  // 		index = i;
  // 	}
  // })

  const delItem = tags.list[index];
  tags.delTagsItem(index);
  const item = tags.list[index] ? tags.list[index] : tags.list[index - 1];
  if (item) {
    delItem.path === route.fullPath && router.push(item.path);
  } else {
    router.push("/dashboard");
  }
};

// 设置标签
const setTags = (route: any) => {
  // alert(tags.list.length)
  if (tags.list.length >= 8) tags.delTagsItem(1);
  let _CURRENTPATH_STR: any = sessionStorage.getItem("_CURRENTPATH_");
  if (_CURRENTPATH_STR) {
    let _CURRENTPATH_JSON = JSON.parse(_CURRENTPATH_STR);
    // if(_CURRENTPATH_JSON.path !='/dashboard'){
    tags.setTagsItem(_CURRENTPATH_JSON);
    // }
  } else {
    tags.setTagsItem({
      name: "dashboardName",
      path: "/dashboard",
      title: "Home",
    });
  }
};
setTags(route);
onBeforeRouteUpdate((to) => {
  setTags(to);
});

// 关闭全部标签
const closeAll = () => {
  tags.clearTags();
  router.push("/dashboard");
};
// 关闭其他标签
const closeOther = () => {
  const curItem = tags.list.filter((item: any) => {
    return item.path === route.fullPath;
  });
  tags.closeTagsOther(curItem);
};
const handleTags = (command: string) => {
  command === "other" ? closeOther() : closeAll();
};

// 关闭当前页面的标签页
// tags.closeCurrentTag({
//     $router: router,
//     $route: route
// });
</script>

<style>
.tags {
  position: relative;
  height: 30px;
  overflow: hidden;
  background: #fff;
  padding-right: 120px;
  box-shadow: 0 5px 10px #ddd;
}

.tags ul {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.tags-li {
  display: flex;
  align-items: center;
  float: left;
  margin: 3px 5px 2px 3px;
  border-radius: 3px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 23px;
  border: 1px solid #e9eaec;
  background: #fff;
  padding: 0 5px 0 12px;
  color: #666;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.tags-li:not(.active):hover {
  background: #f8f8f8;
}

.tags-li.active {
  color: #fff;
}

.tags-li-title {
  float: left;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
}

.tags-li.active .tags-li-title {
  color: #fff;
}

.tags-close-box {
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  padding-top: 1px;
  text-align: center;
  width: 110px;
  height: 30px;
  background: #fff;
  box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
</style>
