import { defineStore } from "pinia";
export const useUserInfo = defineStore("userInfo", {
  state: () => {
    return {
      userData: {} as any
    };
  },
  actions: {
    setUserData(data: any) {
      this.userData = data; // 设置用户数据
    },
    clearUserData() {
      this.userData = {}; // 清除用户数据
    }
  }
});
