let menus = {
  menuId: 100001013,
  parentId: 3,
  parentName: null,
  name: "客户端",
  systemCode: "tms",
  type: 1,
  sortord: 2,
  outsideChain: 0,
  displayStatus: 1,
  menuStatus: 1,
  menuIcon: "el-icon-phone-outline",
  componentPath: "0",
  permission: "Customer",
  cache: 0,
  subsystem: "",
  flag: 0,
  showParent: 1,
  tab: 0,
  routePath: "0",
  englishName: "Customer",
  createTime: null,
  menuIds: null,
  children: [
    {
      menuId: 100001015,
      parentId: 100001013,
      parentName: null,
      name: "Customer Management",
      systemCode: "tms",
      type: 1,
      sortord: 1,
      outsideChain: 0,
      displayStatus: 1,
      menuStatus: 1,
      menuIcon: "el-icon-phone-outline",
      componentPath: "Layout",
      permission: "1",
      cache: 1,
      subsystem: "",
      flag: 0,
      showParent: 1,
      tab: 0,
      routePath: "/customer-Management",
      englishName: "CustomerManagement",
      createTime: null,
      menuIds: null,
      children: [
        {
          menuId: 100001017,
          parentId: 100001015,
          parentName: null,
          name: "Customer Center",
          systemCode: "tms",
          type: 1,
          sortord: 0,
          outsideChain: 0,
          displayStatus: 1,
          menuStatus: 1,
          menuIcon: "el-icon-phone-outline",
          componentPath: "customer-management/customer-center",
          permission: "air",
          cache: 1,
          subsystem: null,
          flag: 0,
          showParent: 1,
          tab: 0,
          routePath: "/customer-Center",
          englishName: "CustomerCenter",
          createTime: null,
          menuIds: null,
          children: []
        }
      ]
    },
    {
      menuId: 100001016,
      parentId: 100001013,
      parentName: null,
      name: "System Management",
      systemCode: "tms",
      type: 1,
      sortord: 1,
      outsideChain: 0,
      displayStatus: 1,
      menuStatus: 1,
      menuIcon: "el-icon-phone-outline",
      componentPath: "Layout",
      permission: "1",
      cache: 1,
      subsystem: "",
      flag: 0,
      showParent: 1,
      tab: 0,
      routePath: "/system-management",
      englishName: "System Management",
      createTime: null,
      menuIds: null,
      children: [
        {
          menuId: 100001017,
          parentId: 100001016,
          parentName: null,
          name: "Home Carousel",
          systemCode: "tms",
          type: 1,
          sortord: 0,
          outsideChain: 0,
          displayStatus: 1,
          menuStatus: 1,
          menuIcon: "el-icon-phone-outline",
          componentPath: "system-management/carousel",
          permission: "air",
          cache: 1,
          subsystem: null,
          flag: 0,
          showParent: 1,
          tab: 0,
          routePath: "/carousel",
          englishName: "Carousel",
          createTime: null,
          menuIds: null,
          children: []
        },

        {
          menuId: 100001018,
          parentId: 100001016,
          parentName: null,
          name: "Best Sellers",
          systemCode: "tms",
          type: 1,
          sortord: 0,
          outsideChain: 0,
          displayStatus: 1,
          menuStatus: 1,
          menuIcon: "el-icon-phone-outline",
          componentPath: "system-management/best-sellers",
          permission: "air",
          cache: 1,
          subsystem: null,
          flag: 0,
          showParent: 1,
          tab: 0,
          routePath: "/best-sellers",
          englishName: "BestSellers",
          createTime: null,
          menuIds: null,
          children: []
        },
        {
          menuId: 100001024,
          parentId: 100001016,
          parentName: null,
          name: "New Arrivals",
          systemCode: "tms",
          type: 1,
          sortord: 0,
          outsideChain: 0,
          displayStatus: 1,
          menuStatus: 1,
          menuIcon: "el-icon-phone-outline",
          componentPath: "system-management/new-arrival",
          permission: "air",
          cache: 1,
          subsystem: null,
          flag: 0,
          showParent: 1,
          tab: 0,
          routePath: "/new-arrival",
          englishName: "newArrival",
          createTime: null,
          menuIds: null,
          children: []
        },
        {
          menuId: 100001019,
          parentId: 100001016,
          parentName: null,
          name: "Top Categories",
          systemCode: "tms",
          type: 1,
          sortord: 0,
          outsideChain: 0,
          displayStatus: 1,
          menuStatus: 1,
          menuIcon: "el-icon-phone-outline",
          componentPath: "system-management/top-categories",
          permission: "air",
          cache: 1,
          subsystem: null,
          flag: 0,
          showParent: 1,
          tab: 0,
          routePath: "/top-categories",
          englishName: "BestSellers",
          createTime: null,
          menuIds: null,
          children: []
        }
      ]
    },
    {
      menuId: 100001020,
      parentId: 100001013,
      parentName: null,
      name: "Contact Us",
      systemCode: "tms",
      type: 1,
      sortord: 1,
      outsideChain: 0,
      displayStatus: 1,
      menuStatus: 1,
      menuIcon: "el-icon-phone-outline",
      componentPath: "Layout",
      permission: "1",
      cache: 1,
      subsystem: "",
      flag: 0,
      showParent: 1,
      tab: 0,
      routePath: "/contact-us",
      englishName: "Contact Us",
      createTime: null,
      menuIds: null,
      children: [
        {
          menuId: 100001021,
          parentId: 100001020,
          parentName: null,
          name: "Quote",
          systemCode: "tms",
          type: 1,
          sortord: 0,
          outsideChain: 0,
          displayStatus: 1,
          menuStatus: 1,
          menuIcon: "el-icon-phone-outline",
          componentPath: "contact-us/quote",
          permission: "air",
          cache: 1,
          subsystem: null,
          flag: 0,
          showParent: 1,
          tab: 0,
          routePath: "/quote",
          englishName: "Quote",
          createTime: null,
          menuIds: null,
          children: []
        },

        {
          menuId: 100001022,
          parentId: 100001020,
          parentName: null,
          name: "Returns & Refunds",
          systemCode: "tms",
          type: 1,
          sortord: 0,
          outsideChain: 0,
          displayStatus: 1,
          menuStatus: 1,
          menuIcon: "el-icon-phone-outline",
          componentPath: "contact-us/returns-refunds",
          permission: "air",
          cache: 1,
          subsystem: null,
          flag: 0,
          showParent: 1,
          tab: 0,
          routePath: "/returns-refunds",
          englishName: "ReturnsRefunds",
          createTime: null,
          menuIds: null,
          children: []
        },
        {
          menuId: 100001023,
          parentId: 100001020,
          parentName: null,
          name: "Become Our Supplier",
          systemCode: "tms",
          type: 1,
          sortord: 0,
          outsideChain: 0,
          displayStatus: 1,
          menuStatus: 1,
          menuIcon: "el-icon-phone-outline",
          componentPath: "contact-us/become-our-supplier",
          permission: "air",
          cache: 1,
          subsystem: null,
          flag: 0,
          showParent: 1,
          tab: 0,
          routePath: "/become-our-supplier",
          englishName: "becomeOurSupplier",
          createTime: null,
          menuIds: null,
          children: []
        }
      ]
    }
  ]
};

export { menus };
