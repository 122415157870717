<template>
    <div class="pagination-box">
    <el-pagination
      :current-page="pageNum"
      :page-size="pageSize"
      :page-sizes="props.pageSizes"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>  
</template>
<script setup lang="ts">
  import { ref } from "vue";
  const emit = defineEmits(['success'])
  const props = defineProps({
    pageNum:{
      type:Number,
      default:1
    },
    pageSize:{
      type:Number,
      default:10
    },
    total:{
      type:Number,
      default:0
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 50, 100]
    }
  })
  // const pageSizes = ref([10, 20, 50, 100])
  const handleSizeChange = (val:number)=>{
    const data = {
      pageNum:1,
      pageSize:val
    }
   emit('success',data)
  }
  const handleCurrentChange = (val:number)=>{
    const data = {
      pageNum:val,
      pageSize:props.pageSize
    }
    emit('success',data)
  }
</script>
<style scope lang="scss">
.pagination-box{
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px 0px 10px #ddd;
  margin-top: 10px;
}

</style>